.linkItem::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  border-radius: 5px;
  height: 2px;
  bottom: -1px;
  left: 0;
  z-index: -10;
  background-color: rgb(59 130 246);
  background: rgb(59 130 246);
  /* transform-origin: bottom right; */
  /* transition: transform 0.3s ease-out; */
}

.menuItems li a:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  /* border-radius: 5px; */
  height: 2px;
  bottom: -1px;
  left: 0;
  z-index: -10;
  /* background-color: #fdc339; */
  /* background: #fdc339; */
  /* transition: transform 0.3s ease-out; */
  transform: scaleX(1);
  /* transform-origin: bottom left; */
}
.menuItems li a.active::after {
  transform: scaleX(1);
  /* transform-origin: bottom left; */
}

@media screen and (min-width: 1200px) {
  .linkItem::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    border-radius: 5px;
    height: 2px;
    bottom: -4px;
    left: 0;
    z-index: -10;
    background-color: rgb(59 130 246);
    background: rgb(59 130 246);
    /* transform-origin: bottom right; */
    /* transition: transform 0.3s ease-out; */
  }
  
  .menuItems li a:hover::after {
    content: "";
    position: absolute;
    width: 100%;
    border-radius: 5px;
    height: 2px;
    bottom: -4px;
    left: 0;
    z-index: -10;
    /* background-color: rgb(59 130 246);
    background: rgb(59 130 246); */
    /* transition: transform 0.3s ease-out; */
    transform: scaleX(1);
    /* transform-origin: bottom left; */
  }
  .menuItems li a.active::after {
    transform: scaleX(1);
    /* transform-origin: bottom left; */
  }
}