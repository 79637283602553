/* .product-images-slider .swiper-button-next::after {
  content: url("../../../assets/img/d-forward.png");  
}

.product-images-slider .swiper-button-prev:hover::after {
 content: url("../../../assets/img/backward.png");
}

.product-images-slider .swiper-button-prev::after {
  content: url("../../../assets/img/d-background.png");

}
.product-images-slider .swiper-button-next:hover::after {
  
    content: url("../../../assets/img/forward.png");
  
} */

.small-bullet-point {
  width: 8px;
  height: 8px;
  background-color: gray;
  border-radius: 100%;
  margin: 0px 6px;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  background-color: rgb(0, 132, 255) !important;
}

.swiper-pagination-bullet-only-child {
  visibility: visible !important;
}